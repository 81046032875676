<template>
  <div>
    <div>
      <div class="row">
        <div class="col-5">
          <lable
            for="ocnNumber"
            class="float-right"
          >
            OCN number
          </lable>
        </div>
        <div class="col-6">
          <a-input
            v-model="OCNnumber"
            style="width: 50%;"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-5">
          <lable
            for="uploadNECACertification"
            class="float-right"
          >
            Upload NECA Certification
          </lable>
        </div>
        <div class="col-6">
          <div
            class="upload-box"
            style="width: 50%;"
          >
            <div class="upload-zone dropzone dz-clickable">
              <div class="dz-message">
                <input
                  id="fileupload7"
                  type="file"
                  name="fileupload7"
                  accept=".jpeg, .png, .jpg, .pdf"
                  class="required"
                  @change="file = $event.srcElement.files[0]"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div />
  </div>
</template>

<script>
export default {
  name: "AddMyOcn",
  data: () => ({
    OCNnumber: '',
    file: null
  }),
  created() {
  }
}
</script>

<style scoped>
#num{
  height: 60px;
  width: 400px;
  margin-left: 10%;
}
</style>